import orm from 'models'
import getEntities from 'selectors/entities'

export const getDocumentsForDossier = (state, props) => {
  const dossierId = props.dossierId

  const dossier = dossierId && orm.session(getEntities(state, props)).dossier.withId(dossierId)
  if(!dossier) return []

  return dossier.documents.toModelArray().map(item => {
    const { ref } = item
    return {
      ...ref
    }
  })
}
