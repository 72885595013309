const templateSchema = function(state, props) {
  const uiSchema = {
    "ui:readonly": props.readonly,
    "subject": {
      "ui:autofocus": true,
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width: 6
      }
    },
    "body": {
      "ui:widget": "HtmlWidget",
      "ui:emptyValue": "",
      "ui:placeholder": "",
      "ui:options": {
        width:12,
        mentionOptions: ["patient_first_name", "patient_last_name", "consultation_dates", "consultation_dates_and_hours", "first_consult_date"],
        mentionExamples: ["Sigmund", "Freud", "16-10-2023\n26-10-2023\n3-11-2023\n10-11-2023", "16-10-2023 om 13u\n26-10-2023 om 15u30\n3-11-2023 om 13u\n10-11-2023 om 14u", "16-10-2023"]
      },
    },
    "officeId": {
      "ui:widget": "hidden"
    },
    "employmentId": {
      "ui:widget": "hidden"
    }
  }

return uiSchema
}

export default templateSchema;

