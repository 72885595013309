import LocationForm from 'components/locations/LocationForm';
import LocationsListContainer from 'components/locations/LocationsListContainer';
import LocationsMenuContainer from 'components/locations/LocationsMenuContainer';
import React from 'react'
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom'
import { Message } from "semantic-ui-react";
import { Segment, Sidebar } from 'semantic-ui-react'
import { getCurrentEmployment } from "../../selectors/employments";

class Locations extends React.Component {
  render () {
    let readOnly = !this.props.canEdit
    return (
      <div>
        {readOnly ? <Message>{I18n.t('components.locations_list.read_only')}</Message> : null}
        <Switch>
          <Route path={Routes.location_path({ id: ":id", _options: true})} render={ props => (
            <Sidebar.Pushable as={Segment} className= "min-height-90">
              <LocationsMenuContainer readonly={readOnly}/>
              <Sidebar.Pusher className="left-sidebar-margin">
                <LocationForm entityId={props.match.params.id} readonly={readOnly}/>
              </Sidebar.Pusher>
            </Sidebar.Pushable>
           )} />
          <Route path={Routes.locations_path()}  render={(props) => <LocationsListContainer readonly={readOnly}/> }/>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const current = getCurrentEmployment(state)
  const canEdit = current && current.roles.includes('configurator')
  return {
    canEdit: canEdit
  };
}

export default connect(mapStateToProps)(Locations);

