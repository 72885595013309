import React from 'react'
import { Image, List } from 'semantic-ui-react'

class EmploymentsListItem extends React.Component {

  render() {
    const employment = this.props.employment

    return (
      <List.Item key={employment.id}>
        <Image src={employment.photoUrl} avatar/>
        <List.Content>
          <List.Header>{`${employment.user?.firstName} ${employment.user?.lastName}`}</List.Header>
          <List.Description>{this.getEmploymentType(employment.roles)}</List.Description>
        </List.Content>
      </List.Item>
    )
  }

  getEmploymentType(roles) {
    if(roles.includes("practitioner") && roles.includes('administrator')) {
      return I18n.t(`components.employments_list_item.employment_type.practice_manager`)
    } else if(roles.includes("administrator")) {
      return I18n.t(`components.employments_list_item.employment_type.administrative`)
    } else if(roles.includes('practitioner')) {
      return I18n.t(`components.employments_list_item.employment_type.practitioner`)
    }
  }
}

export default EmploymentsListItem;
