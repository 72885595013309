import React from 'react'
import { connect } from "react-redux";
import ui from 'redux-ui'
import { Select } from "semantic-ui-react";
import { FormField } from "semantic-ui-react";
import { Button, Form, Header, Icon } from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'
import { fetchEntities } from "../../../actions/entities";
import { getTemplates } from "../../../selectors/templates";
import RichTextEditor from "../../forms/richText/RichTextEditor";

class TemplatedDocumentForm extends React.Component {

  componentDidMount() {
    this.props.fetchTemplates();
  }

  constructor(props) {
    super(props);
    this.state = {text: '' ,template:''}
  }

  handleTemplateChange = (event,props) => {
    this.setState({template: props.value})
    this.props.fetchFilledTemplate(props.value)
    this.forceUpdate()
  }

  handleTextChange = (value) => {
    this.setState({text: value})
  }

  handleSubmit = (template) => {
    //note: this is dirty hack; ideally the state is kept in the redux store instead!!
    let body = this.state.text === "<p></p>" ? template.filled :  this.state.text
    this.props.onSubmit(body, template.subject)
    this.props.updateUI('open', false)
  }

  render() {
    const templates = this.props.templates
    const selectOptions = templates && templates.map(t => {return {key: t.id, value: t.id, text: t.subject}})
    const currentTemplate = templates.find(t => t.id === this.state.template)
    const filled = currentTemplate && currentTemplate.filled

    return (
      <Modal
      trigger={this.props.trigger} closeIcon
      open={this.props.ui.open}
      onClose={() => this.props.updateUI('open', false)}
      onOpen={() => this.props.updateUI('open', true)}
      >
        <Header content={I18n.t('components.dossier.documents.template_form.title')} />
        <Modal.Content>
          <Form>
            <FormField>
              <label>{I18n.t('components.dossier.documents.template_form.template_field')}</label>
              <Select selection search options={selectOptions} onChange={this.handleTemplateChange}
                      value={this.state.template}/>
            </FormField>
            <FormField>
              <RichTextEditor serializeToHtml={true} key={this.state.template}
                              onChange={this.handleTextChange}
                              value={filled}
                              loading = {currentTemplate && !filled}
              />
            </FormField>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green'
          onClick={() => this.handleSubmit(currentTemplate)}>
            <Icon name='file pdf outline' /> {I18n.t('generic.create')}
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return { templates: getTemplates(state) };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchTemplates: () => {
      return dispatch(fetchEntities('templates'))
    },
    fetchFilledTemplate: (templateId) => {
      return dispatch(fetchEntities('templates', { name: 'template', filter: { id: templateId, dossierId: ownProps.dossierId }, page: 1 }))
    },
  };
}
export default ui({state: { open: false}})(connect(mapStateToProps, mapDispatchToProps)(TemplatedDocumentForm));
