import React from 'react'
import { connect } from 'react-redux'
import { DropdownItem } from "semantic-ui-react";
import { DropdownMenu } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { Popup } from "semantic-ui-react";
import { Menu } from 'semantic-ui-react'
import { createEntityWithDefaults } from "../../../actions/entities";
import { upsertEntity } from "../../../actions/entities";
import { getDossier } from "../../../selectors/dossiers";
import DossierCertificateForm from './DossierCertificateForm'
import TemplatedDocumentForm from "./TemplatedDocumentForm";

class DocumentsListActions extends React.Component {
  render() {
    let HealthFundButton = <DropdownItem>
      {I18n.t(`components.dossier.documents.create_health_fund_certificate`)}
    </DropdownItem>

    let TemplateButton = <DropdownItem>
      {I18n.t(`components.dossier.documents.create_from_template_button`)}
    </DropdownItem>


    return (
        <Menu.Item key={'document-actions'}>
          <Dropdown text={I18n.t(`components.dossier.documents.generate`)} button>
            <DropdownMenu>
              <TemplatedDocumentForm trigger={TemplateButton} dossierId={this.props.dossierId}
                                     onSubmit={(text, name) => this.props.submit('report', { text: text, name: name })}/>

              <DropdownItem onClick={() => this.props.submit('bilan')}>
                {I18n.t(`components.dossier.documents.create_bilan`)}
              </DropdownItem>
              {this.props.healthFundId ?
                <DossierCertificateForm trigger={HealthFundButton} dossierId={this.props.dossierId}
                                        onSubmit={(startDate) => this.props.submit('healthfund', { startDate: startDate })}/>
                :
                <Popup trigger={HealthFundButton} header={I18n.t(`components.dossier_documents.health_fund_error`)}/>
              }
            </DropdownMenu>
          </Dropdown>
        </Menu.Item>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const dossier = getDossier(state, ownProps.dossierId);

  return {
    healthFundId: dossier.healthFundId
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const dossierId = ownProps.dossierId
  return {
    submit: (type, extraParams = {}) => {
      let documentId = dispatch(createEntityWithDefaults('document', {
        type: type,
        dossierId: dossierId, ...extraParams
      }))
      dispatch(upsertEntity('document', documentId))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsListActions)
