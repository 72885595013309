import EntitiesList from 'components/entities/EntitiesList';
import React from "react";
import { connect } from 'react-redux'
import { Form } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import { Card } from "semantic-ui-react";
import { getDocumentsForDossier } from "../../../selectors/documents";
import DocumentsListActions from "./DocumentsListActions";
import DocumentsListItem from "./DocumentsListItem";

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getDocumentsForDossier(state, {dossierId: ownProps.dossierId}),
    entityName: 'document',
    item: DocumentsListItem,
    listComponent: Card.Group,
    form: Form,
    loading: state.meta?.location?.loading,
    error: state.meta?.location?.error,
    addButton: AddButton
  };
}

const AddButton = (props) => {return(
  <Button onClick={props.onClick} disabled>
    <Icon name='upload'/>
    {I18n.t(`components.dossier.documents.upload`)}
  </Button>
)}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    extraActions: () => {
      return <DocumentsListActions dossierId={ownProps.dossierId}/>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
