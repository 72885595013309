import orm from 'models'

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { createSelector as ormSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import getEntities from 'selectors/entities'
import { getKeyWindowSelector } from 'selectors/key_windows'
import { getCurrentEmployment } from "../employments";

const moment = extendMoment(Moment)

const getConsultations = ormSelector(orm, getEntities, (session) => {
  return session.consultation.all().toModelArray()
                .filter(consultation => !consultation.deleted)
                .map(consultation => {
                  const { ref } = consultation
                  return {
                    ...ref,
                    dossier: session.dossier.withId(consultation.dossierId),
                    consultationType: session.consultationType.withId(consultation.consultationTypeId),
                    event: session.event.withId(consultation.eventId)
                  }
                })
})

const getOwnConsultations = createSelector([getConsultations, getCurrentEmployment],
                                           (consultations, employment) => {
                                             return consultations.filter( consultation => consultation.practioner && consultation.practioner.id.toString() === employment.id  )
                                           }
)

export const getSortedConsultationsForListByKeyWindow = (keyWindow) => {
  return createSelector([getKeyWindowSelector('consultation', keyWindow, getConsultations)], (consultations) => _.sortBy(consultations, [(consultation) => consultation.event && consultation.event.startTime] ))
}

export const getOwnSortedConsultationsForListByKeyWindow = (keyWindow) => {
  return createSelector([getKeyWindowSelector('consultation', keyWindow, getOwnConsultations)], (consultations) => _.sortBy(consultations, [(consultation) => consultation.event && consultation.event.startTime] ))
}

const getDateRange = (state, props) => moment.range(props.startDate, props.endDate)

export const getConsultationsInDateRange = createSelector([getKeyWindowSelector('consultation', 'stats', getOwnConsultations), getDateRange],
                                                          (consultations, dateRange) => {
                                                            return consultations.filter( consultation => consultation.event && consultation.event.startTime && dateRange.contains(moment(consultation.event.startTime)))
                                                          }
)




