import { attr, fk } from 'redux-orm'
import Base from './Base'

class Employment extends Base {
  static get fields() {
    return {
      id: attr(),
      roles: attr(),
      photoUrl: attr(),
      current: attr(),
      name: attr(),
      riziv_number: attr(),
      userId: fk({ to: 'user', as: 'user', relatedName: 'employments'}),
    }
  }
}

Employment.modelName = 'employment'

export default Employment;
