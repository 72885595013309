import SchemaForm from 'components/forms/SchemaForm'
import React from 'react'
import { connect } from 'react-redux'
import templateSchema from "../../forms/templateSchema";

class TemplateForm extends React.Component {
  render () {
    return (
      <SchemaForm entityName='template' {...this.props}/>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    uiSchema: templateSchema(state, ownProps)
  }
}

TemplateForm.defaultProps = {
  submitButton: true
}

export default connect(mapStateToProps)(TemplateForm);

