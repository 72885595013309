import DestroyModal from 'components/generics/DestroyModal'
import React from 'react'
import { Button, Card, Icon } from 'semantic-ui-react'
import EditModal from "../forms/EditModal";
import TemplateForm from "./TemplateForm";


export default class TemplateListItem extends React.Component {
  handleDestroy = () => {
    this.props.destroyEntity(this.props.template)
  }

  render () {
    const template = this.props.template
    let TemplateHeader = (props) => {
      return(
      <Card.Content onClick={props.onClick} href>
        <Card.Header>
          {template.subject}
        </Card.Header>
      </Card.Content>)
    }

    return (
      <Card key={template.id}>
        <EditModal entityName="template" EntityForm={TemplateForm}
                   entityId={template.id} trigger={TemplateHeader}/>

        <Card.Content extra>
          <DestroyModal easy
            handleDestroy={this.handleDestroy}
            trigger={(props) => { return(
              <Button color='red' size='mini' compact floated='right' inverted
                      onClick={(event) => {event.stopPropagation(); event.preventDefault(); return props.onClick() }}>
                <Icon name='trash'/> {I18n.t('components.calendar_list.destroy')}
              </Button>)
            }}>
          </DestroyModal>
        </Card.Content>
      </Card>


    )
  }
}


