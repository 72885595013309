import React from 'react'
import { createEditor } from "slate";
import { Editable, Slate } from "slate-react";
import EditorElement from "./EditorElement";
import EditorLeaf from "./EditorLeaf";
import { deserialize } from "./HtmlSerialisation";

export default class RichTextRender extends React.Component {
  render() {
    const renderElement = props => <EditorElement {...props} />
    const renderLeaf = props => <EditorLeaf {...props} />
    const editor = createEditor();
    return(
      <Slate editor={editor} value={JSON.parse(this.props.content)} >
        <Editable readOnly
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
    );
  }
}

export class DynamicRichTextRender extends React.Component {
  render() {
    const renderElement = props => <EditorElement {...props} />
    const renderLeaf = props => <EditorLeaf {...props} />
    const editor = createEditor();

    let initialValue
    if(this.props.renderHtml){
      const document = new DOMParser().parseFromString(this.props.content, 'text/html')
      initialValue = deserialize(document.body)
    } else {
      initialValue = JSON.parse(this.props.content)
    }

    return(
      <Slate key={hashCode(this.props.content)} editor={editor} value={initialValue} >
        <Editable readOnly
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
        />
      </Slate>
    );
  }
}

const hashCode = s => s && s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)


