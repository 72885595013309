import React, { Fragment } from 'react'
import { Button, Form, Header, Icon, Input } from 'semantic-ui-react'
import { Modal } from 'semantic-ui-react-with-store'

import ui from "../../redux-ui";

class DestroyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.easy ? {confirmation: 'DELETE'} :  {confirmation: ''}
  }

  handleConfirmationChange = (event,props) => {
    this.setState({confirmation: props.value})
  }

  render() {
    const Trigger = this.props.trigger

    return (
      <Fragment>
        <Trigger key='trigger' onClick={() => {
          this.props.updateUI('open', true)
        }}/>
        <Modal size='mini' closeIcon open={this.props.ui.open} onClose={() => this.props.updateUI('open', false)}>
          <Header content={I18n.t('components.destroy_modal.title')}/>

          {this.props.easy ? null :
           [<Modal.Content key={'destroy-modal-children'}>
              {this.props.children}
            </Modal.Content>,
          <Modal.Content key={'destroy-modal-confirmation'}>
            <Form.Field>
              {I18n.t('components.destroy_modal.confirmation')}
              <Input onChange={this.handleConfirmationChange}/>
            </Form.Field>
          </Modal.Content>] }
          <Modal.Actions>
            <Button color='red' inverted disabled={( this.state.confirmation !== 'DELETE')}
                    onClick={this.props.handleDestroy}>
              <Icon name='trash'/> {I18n.t('components.destroy_modal.destroy')}
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    )
  }
}

export default ui({state: { open: false}})(DestroyModal);
