import * as ActionCable from '@rails/actioncable'
import ChannelsInfo from 'components/generics/ChannelsInfo'
import { ConnectedRouter } from 'connected-react-router'

import ScrollContext from 'contexts/ScrollContext'
import history from 'custom_history'
import React from 'react'

import { DragDropContext } from 'react-dnd';
import MouseBackend from 'react-dnd-mouse-backend';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { activeColor } from 'route_helpers'
import { Header, Icon, Menu, Segment } from 'semantic-ui-react'
import store from 'store.js'
import Agenda from './agenda/Agenda'
import Compass from './compass/Compass'
import Configuration from './configuration/Configuration'
import Contacts from './contacts/Contacts'

import DossierFollowUp from './dossiers/dossierFollowUp/DossierFollowUp'
import Dossiers from './dossiers/Dossiers'

import ErrorPage from './generics/ErrorPage'
import Flash from './generics/Flash'
import MainMenu from './generics/MainMenu'

import MainModal from './generics/MainModal'
import ProgressBars from './generics/ProgressBars'
import Invoices from './invoices/Invoices'
import ManagementMenu from './navigation/ManagementMenu'

import OverviewMenu from './navigation/OverviewMenu'
import Notifications from './notifications/Notifications'
import Organisations from './organisations/Organisations'
import SetupWizardContainer from "./SetupWizard/SetupWizardContainer";

setConfig({ logLevel: 'debug' })

class MainApp extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContext = React.createRef();
    this.state = {
      error: null
    };
    ActionCable.logger.enabled = true
  }

  render () {
    if (this.state.error || (store.errors && store.errors.error)){
      return(<ErrorPage redirectUrl={Routes.compass_path()} error={this.state.error || (store.errors && store.errors.error)}/>)
    } else {
      return (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/:officeSlug/dossiers/:dossierId/editor" render={(props) => {
                return (
                    <div className="full-main" ref={this.scrollContext}>
                      <ScrollContext.Provider value={this.scrollContext}>
                        <DossierFollowUp dossierId={props.match.params.dossierId}/>
                      </ScrollContext.Provider>
                    </div>
                  )
              }}/>
              <Route path="/:officeSlug/*" render={(props) => {
                return (<div className="page-layout">
                    <MainModal/>
                    <SetupWizardContainer/>
                    <MainMenu color={activeColor(props.match.url)}/>
                    <div className="main" ref={this.scrollContext}>
                      <div className="main-body">
                        <div style={{position: 'absolute', bottom: 10, right: 10, zIndex: 1000000 }}>
                          <ChannelsInfo/>
                        </div>
                        <ProgressBars/>
                        <Flash/>
                        <ScrollContext.Provider value={this.scrollContext}>
                          <Switch>
                            <Route path={Routes.compass_path()} component={Compass}/>
                            <Route path={Routes.consultations_path()} component={Agenda}/>
                            <Route path={Routes.dossiers_path()} component={Dossiers}/>
                            <Route path={Routes.dossier_infos_path()} component={Dossiers}/>
                            <Route path={Routes.dossier_financials_path()} component={Dossiers}/>
                            <Route path={Routes.contacts_path()} component={Contacts}/>
                            <Route path={Routes.organisations_path()} component={Organisations}/>
                            {/* <Route path={Routes.receipts_journal_path()} component={ReceiptsJournal}/> */}
                            <Route path={Routes.invoices_path()} component={Invoices}/>
                            <Route path={Routes.notifications_path()} component={Notifications}/>
                            <Route path={Routes.configuration_path()} component={Configuration}/>
                            <Route path={Routes.locations_path()} component={Configuration}/>
                            <Route path={Routes.email_templates_path()} component={Configuration}/>
                            <Route path={Routes.invoice_configuration_path()} component={Configuration}/>
                            <Route path={Routes.calendars_path()} component={Configuration}/>
                            <Route path={Routes.templates_path()} component={Configuration}/>
                            <Route path={Routes.consultation_prices_path()} component={Configuration}/>
                            <Route path={Routes.employments_path()} component={Configuration}/>
                          </Switch>
                        </ScrollContext.Provider>
                      </div>
                    </div>
                  <Switch>
                    <Route path={Routes.invoices_path()} component={ManagementMenu}/>
                    <Route path={Routes.contacts_path()} component={ManagementMenu}/>
                    {/*<Route path={Routes.organisations_path()} component={ManagementMenu}/>*/}
                    {/* <Route path={Routes.receipts_journal_path()} component={ManagementMenu}/> */}
                    <Route path="*" component={OverviewMenu}/>
                  </Switch>
                </div>) }
              } />
            <Route path="/" render={(props) =>
                <div className="page-layout">
                  <Menu color="grey">
                    <Menu.Item>
                      ZorgMee
                    </Menu.Item>
                    <Menu.Menu position='right'>
                      <Menu.Item as='a' href={Routes.destroy_user_session_path()} data-method="delete" >
                        <Icon size='large' name="sign out"/>
                      </Menu.Item>
                    </Menu.Menu>
                  </Menu>
                  <div className="main">
                    <Segment>
                      <Header>
                        {I18n.t('components.main_app.welcome')}
                      </Header>
                    </Segment>
                  </div>
                </div>
            }/>
        </Switch>
      </ConnectedRouter>
    </Provider>
      )
    }
  }
}

export default hot(DragDropContext(MouseBackend)(MainApp));
