import { attr, fk } from 'redux-orm'
import Base from './Base'

class Template extends Base {
  static get fields() {
    return {
      id: attr(),
      subject: attr(),
      body: attr(),
      officeId: fk({to: 'office', as: 'office', relatedName: 'templates'}),
      employmentId: fk({to: 'employment', as: 'employment', relatedName: 'templates'}),
    }
  }
}

Template.modelName = 'template'

export default Template;
