import EntitiesList from 'components/entities/EntitiesList';
import { connect } from 'react-redux'
import { Card } from "semantic-ui-react";
import { refreshEntities } from "../../actions/entities";
import { destroyEntity } from "../../actions/entities";
import { getTemplates } from "../../selectors/templates";
import TemplateForm from "./TemplateForm";
import TemplateListItem from "./TemplateListItem";

const mapStateToProps = (state, ownProps) => {
  return {
    entities: getTemplates(state),
    entityName: 'template',
    item: TemplateListItem,
    form: TemplateForm,
    listComponent: Card.Group,
    loading: state.meta?.location?.loading,
    error: state.meta?.location?.error
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    destroyEntity: (template) => {
      dispatch(destroyEntity('template', template.id)).then(
        () => {
          dispatch(refreshEntities('templates'))}
      )

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesList)
