import React from 'react'
import { Card } from 'semantic-ui-react'


export default class DocumentsListItem extends React.Component {

  render () {
    const document = this.props.document
    let hrefString = Routes.download_api_document_path({ id: document.id, _options: true })
    return (
      <Card key={document.id} href={hrefString}>
      <Card.Content>
        <Card.Header >
          {document.name}
        </Card.Header>
      </Card.Content>
    </Card>)
  }
}


