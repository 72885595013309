import { attr, fk } from 'redux-orm'
import Base from './Base'

class Document extends Base {
  static get fields() {
    return {
      id: attr(),
      name: attr(),
      type: attr(),
      attachmentExpiringUrl: attr(),
      attachmentTitle: attr(),
      startDate: attr(),
      text: attr(),
      dossierId: fk({to: 'dossier', as: 'dossier', relatedName: 'documents'}),
    }
  }
}

Document.modelName = 'document';

export default Document;
