import orm from 'models'
import { createSelector as ormSelector } from 'redux-orm'
import getEntities from 'selectors/entities'

export const getTemplates = ormSelector(orm, getEntities, (session) => {
  return session.template.all().toModelArray().map(template => {
    const { ref } = template
    return { ...ref }
  })
})
