import isHotkey from 'is-hotkey'
import React, { useCallback, useMemo } from 'react'
import { PlaceholderLine } from "semantic-ui-react";
import { Placeholder } from "semantic-ui-react";
import { Icon, Menu, Segment } from 'semantic-ui-react'
import { createEditor, Editor, Element as SlateElement, Transforms } from 'slate'
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, useSlate, withReact } from 'slate-react'
import EditorElement from "./EditorElement";
import EditorLeaf from "./EditorLeaf";
import { deserialize } from "./HtmlSerialisation.jsx";
import { serialize } from "./HtmlSerialisation.jsx";
import { MentionsButton } from "./Mentions.jsx";
import { withMentions } from "./Mentions.jsx";

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const RichTextEditor = (props) => {
  const renderElement = useCallback(props => <EditorElement {...props} />, [])
  const renderLeaf = useCallback(props => <EditorLeaf {...props} />, [])
  const editor = useMemo(() => withMentions(withHistory(withReact(createEditor()))), [])

  const html = props.serializeToHtml

  let initialValue = emptyInitialValue
  if(props.value){
    if(html){
      const document = new DOMParser().parseFromString(props.value, 'text/html')
      initialValue = deserialize(document.body)
    } else {
      initialValue = JSON.parse(props.value)
    }
  }

  if(props.loading) return (
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  )

  return (
    //opgepast: breaking change als we Slate bumpen: value moet initialValue worden
    <Slate editor={editor} value={initialValue} onChange={
      value => {
        const serialized = html? serialize(value) : JSON.stringify(value)
        props.onChange(serialized)
      }
      }>
      <Segment attached='top'>
      <Menu icon attached='top'>
        <MarkButton format="bold" icon="bold"/>
        <MarkButton format="italic" icon="italic"/>
        <MarkButton format="underline" icon="underline"/>
        <BlockButton format="heading-two" icon="header"/>
        <BlockButton format="block-quote" icon="quote right"/>
        <BlockButton format="numbered-list" icon="list ol"/>
        <BlockButton format="bulleted-list" icon="list ul"/>
        <BlockButton format="left" icon="align left"/>
        <BlockButton format="center" icon="align center"/>
        <BlockButton format="right" icon="align right"/>
        <BlockButton format="justify" icon="align justify"/>
        {props.mentionOptions && <MentionsButton mentionOptions={props.mentionOptions} editor={editor}/>}
      </Menu>
      </Segment>
      <Segment attached='bottom'>
        <div className="selectable">
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={I18n.t('components.placeholder.content')}
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
          />
        </div>
      </Segment>
    </Slate>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const {selection} = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const BlockButton = ({format, icon}) => {
  const editor = useSlate()
  return (
    <Menu.Item
      name={icon}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onClick={event => {
        event.preventDefault()
        toggleBlock(editor, format)
        ReactEditor.focus(editor)
      }}
    >
      <Icon name={icon}/>
    </Menu.Item>
  )
}

const MarkButton = ({format, icon}) => {
  const editor = useSlate()
  return (
    <Menu.Item
      name={icon}
      active={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault()
        toggleMark(editor, format)
        ReactEditor.focus(editor)
      }}
    >
      <Icon name={icon}/>
    </Menu.Item>
  )
}

const emptyInitialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
]

export default RichTextEditor
