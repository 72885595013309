import { fetchEntities } from 'actions/entities'
import AddNewMenuItem from 'components/generics/AddNewMenuItem'
import NotificationsMenuItem from 'components/generics/NotificationsMenuItem'
import SearchMenuItem from 'components/generics/SearchMenuItem'
import OfficeSelector from 'components/offices/OfficeSelector'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getCurrentEmployment } from 'selectors/employments'

import { Icon, Image, Menu } from 'semantic-ui-react'

class MainMenu extends React.Component {
render () {
    return (
      <Menu color={this.props.color} className={'main-menu'} inverted>
        <Menu.Menu position='left'>
          <Menu.Item as='a' href={Routes.compass_path()}>
            {process.env.NODE_ENV === 'production' ? 'ZorgMee': process.env.NODE_ENV}
          </Menu.Item>
          <OfficeSelector/>
          <Menu.Item>
            { this.props.currentEmployment ? <Image src ={this.props.currentEmployment.photoUrl} avatar spaced='right'/> : null }
            { this.props.currentEmployment?.user ?`${this.props.currentEmployment?.user?.firstName} ${this.props.currentEmployment?.user?.lastName}` : null}
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu position='right'>
          <AddNewMenuItem/>
          <SearchMenuItem/>
          <NotificationsMenuItem/>
          <Menu.Item as={ NavLink } to={Routes.configuration_path()} >
            <Icon size='large' name='settings'/>
          </Menu.Item >
          {this.props.showAdmin &&
          <Menu.Item as='a' href={Routes.admin_dossiers_path()}>
            <Icon size='large' name='building'/>
          </Menu.Item>}
          <Menu.Item as='a' href={Routes.destroy_user_session_path()} data-method="delete" >
            <Icon size='large' name="sign out"/>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const current = getCurrentEmployment(state)
  const showAdmin = current && current.roles.includes('administrator')

  return {
    currentEmployment: current,
    showAdmin: showAdmin
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchEntities: () => {
      dispatch(fetchEntities('employments'))
      dispatch(fetchEntities('users'))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
